/*=================== Шрифт ===================*/


@font-face {
    font-family: 'VelaSans';
    src: url('../fonts/VelaSans-Bold.woff') format('woff');
    font-weight: bold;
}

@font-face {
    font-family: 'VelaSans';
    src: url('../fonts/VelaSans-ExtraBold.woff') format('woff');
    font-weight: 800;
}

@font-face {
    font-family: 'VelaSans';
    src: url('../fonts/VelaSans-ExtraLight.woff') format('woff');
    font-weight: 200;
}

@font-face {
    font-family: 'VelaSans';
    src: url('../fonts/VelaSans-Light.woff') format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'VelaSans';
    src: url('../fonts/VelaSans-Medium.woff') format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'VelaSans';
    src: url('../fonts/VelaSans-Regular.woff') format('woff');
    font-weight: normal;
}

@font-face {
    font-family: 'VelaSans';
    src: url('../fonts/VelaSans-SemiBold.woff') format('woff');
    font-weight: 600;
}



/*=================== VARIABLES CSS ===================*/

:root {
    --header-height: 8rem;

    /*========== Colors ==========*/
    --body-color: #0e0e0e;
    --text-color: #fff;
    --title-color: #fff;
    --text-second-color: #EDEDED;
    --link-hover-color: rgba(255,255,255, .8);
    --text-input-color: rgba(255,255,255, .5);
    --modal-color: #161616;
    --border-color: #282828;
    --span-color: rgba(23,23,23,.9);
    --button-dark-color: #1C1C1C;
    --button-dark-hover-color: #262626;
    --container-color: #171717;
    --container-second-color: rgba(255,255,255, .02);
    --input-color: rgba(216, 216, 216, .12);
    --purple-color: #A663DF;
    --purple-hover-color: #8c63df;
    --pink-color: rgba(255,125,193,1);
    --red-color: rgb(253, 84, 84);
    --red-hover-color: rgb(250, 60, 60);
    --black-color: rgb(0,0,0);
    --white-color: rgb(255,255,255);
    --purple-gradient: linear-gradient(120deg, rgba(255,125,193,1) 0%, rgba(66,68,255,1) 100%);
    --blue-gradient: linear-gradient(120deg, rgba(125,255,180,1) 0%, rgba(66,68,255,1) 100%);
    --red-gradient: linear-gradient(120deg, rgba(255,185,41,1) 0%, rgba(255,125,193,1) 100%);
    --white-gradient: linear-gradient(120deg, rgba(255,255,255,1) 0%, rgba(255,255,255, .6) 50%, rgba(255,255,255, .1) 100%);
    --webkit-blue-gradient: -webkit-linear-gradient(120deg, rgba(125,255,180,1) 0%, rgba(66,68,255,1) 100%);
    --webkit-purple-gradient: -webkit-linear-gradient(120deg, rgba(255,125,193,1) 0%, rgba(66,68,255,1) 100%);

    /*========== Font and typography ==========*/
    --body-font: 'VelaSans', sans-serif;

    /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
    --bigger-font-size: 4rem;
    --big-font-size: 2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1.125rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;

    /*========== Font weight ==========*/
    --font-extra-light: 200;
    --font-light: 300;
    --font-normal: normal;
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: bold;
    --font-extra-bold: 800;

    /*========== Margenes Bottom ==========*/
    /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
    --mb-0-25: .25rem;
    --mb-0-5: .5rem;
    --mb-0-75: .75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;
    --mb-3-5: 3.5rem;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
}

/* Font size for large devices */
@media screen and (min-width: 968px) {
    :root {
        --big-font-size: 3rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
    }
}

/*==================== ЭТО БАЗА ====================*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--body-color);
    color: var(--text-color);
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    margin: var(--header-height) var(--mb-0-5) 0 var(--mb-0-5);
}

footer {
    margin-top: auto;
}


h1, h2, h3, h4 {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}

/*==================== ОСНОВНОЙ КОД ====================*/

/* TODO: Background */

.background_element {
    background: url("../img/biks.png");
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    opacity: .03;
}

/* TODO: Анимации */

.highlight {
    color: var(--text-input-color);
    animation: highlightAnimation 1s ease;
}

@keyframes highlightAnimation {
    0% { color: var(--text-input-color); }
    100% { color: var(--text-color); }
}

/* TODO: Шапка */

@keyframes headerUp {
    0% {transform: translateY(-50px);}
    100% {transform: translateY(0);}
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: var(--mb-0-25) var(--mb-0-5);
    z-index: var(--z-fixed);
    transition: margin 0.3s ease;
    animation: headerUp 1s ease-in-out;
}
header.scroll {
    margin: 0;
}
header.scroll > .header {
    border: none;
    border-radius: 0;
    background-color: var(--span-color);
}
.header {
    max-width: 100vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--mb-0-5) var(--mb-0-75);
    background-color: var(--container-color);
    border: 1px solid var(--border-color);
    border-radius: 10px;
}
.header_left {
    font-weight: var(--font-bold);
    font-size: var(--h3-font-size);
}
.header_left a {
    color: var(--text-color);
    cursor: pointer;
}
.header_left a:hover {
    color: var(--purple-hover-color);
    /*background: var(--purple-gradient);*/
    /*background: var(--webkit-purple-gradient);*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
}


.header_right .info_button{
    border: none;
    outline: none;
    background: initial;
    color: var(--text-color);
    font-size: var(--h2-font-size);
    cursor: pointer;
}
.header_right .info_button:hover {
    /*background: var(--purple-gradient);*/
    /*background: var(--webkit-purple-gradient);*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
    color: var(--purple-hover-color);
}
.header_middle {
    position: relative;
    display: flex;
    max-width: 250px;
    width: 100%;
}

.searchResult {
    position: absolute;
    background: var(--span-color);
    bottom: -55px;
    left: 50%;
    padding: var(--mb-0-5) var(--mb-0-75);
    border-radius: var(--mb-0-5);
    width: 300px;
    margin-left: -150px;
    border: 1px solid var(--border-color);
}
.searchResult span a {
    font-weight: var(--font-light);
    font-size: var(--small-font-size);
    color: var(--text-color);
    text-decoration: underline;
}

.header_middle input {
    display: flex;
    border: none;
    border-bottom: 1px solid var(--border-color);
    outline: none;
    padding: var(--mb-0-25) var(--mb-0-5);
    padding-right: 22px;
    /*border-radius: 10px;*/
    background: initial;
    color: var(--text-input-color);
    font-weight: var(--font-extra-light);
    width: 100%;
}
.header_middle input:focus {
    color: var(--text-color);
    border-bottom: 1px solid var(--purple-color);
}

.header_middle .header_search_button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    outline: none;
    background-color: initial;
    color: var(--text-input-color);
    font-size: var(--normal-font-size);
    font-weight: var(--font-extra-light);
    cursor: pointer;
}

.header_right {
    text-align: center;
    display: flex;
    align-items: center;
}

.drop_down {
    position: relative;
    line-height: 1px;
}

.drop_down .drop_down_title {
    cursor: pointer;
    font-size: var(--h2-font-size);
    line-height: 1px;
}
.drop_down .drop_down_content {
    position: absolute;
    top: 40px;
    right: calc(0px - var(--mb-0-75));
    width: 250px;
    display: flex;
    flex-direction: column;
    background: var(--container-color);
    border: 1px solid var(--border-color);
    border-radius: var(--mb-0-5);
    overflow: hidden;
}

.drop_down .drop_down_content .drop_down_content_row {
    border-bottom: 1px solid var(--border-color);
    text-align: left;
}
.drop_down_content_row.row_title {
    display: flex;
    align-items: center;
    padding: var(--mb-0-5);
}
.drop_down_content_row.row_title i {
    padding-right: var(--mb-0-5);
}
.drop_down .drop_down_content .drop_down_content_row:last-child {
    border-bottom: none;
}

.drop_down .drop_down_content .drop_down_content_row a {
    padding: var(--mb-0-5);
    display: flex;
    align-items: center;
    width: 100%;
    font-size: var(--normal-font-size);
}
.drop_down_content_row a i {
    padding-right: var(--mb-0-5);
}
.drop_down_content_row a:hover {
    background-color: var(--container-second-color);
}

.header_right a {
    color: var(--text-color);
    font-size: var(--small-font-size);
}
.header_right a:last-child{
}
.header_right a.logout_button{
    color: var(--red-color);
}
.header_right a.logout_button:hover{
    color: var(--red-hover-color);
}
.header_right a:hover {
    color: var(--link-hover-color);
}


/* TODO: Модальные окна */

.modal_background {
    display: none;
}
.modal_background.active {
    display: block;
}

@keyframes modalActive {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.modal {
    position: fixed;
    top: calc(50% - 200px);
    left: calc(50% - 325px);
    width: 650px;
    height: 400px;
    background-color: var(--modal-color);
    z-index: var(--z-modal);
    overflow: hidden;
    resize: both;
    border-radius: 20px 20px 0 20px;
    animation: modalActive 0.5s ease;
}
.modal.view {
    z-index: calc(var(--z-modal) + 1);
}

.modal-content {
    user-select: text;

    /*overflow: auto;*/
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    font-weight: var(--font-normal);
    font-size: var(--normal-font-size);
}
.modal-content .table_content {
    overflow: auto;
    /*padding: 0 16px 75px 16px;*/
}

.close-btn {
    cursor: pointer;
    padding: 2px;
    border-radius: 50%;
    font-size: var(--h3-font-size);
    font-weight: var(--font-bold);
    border: 1px solid var(--border-color);
    background: var(--button-dark-color);
    color: var(--text-second-color);
}
.close-btn:hover {
    background: var(--button-dark-hover-color);
}

.modal-header {
    width: 100%;
    cursor: grab;
    user-select: none;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--font-bold);
    font-size: var(--h3-font-size);
    border-bottom: 1px solid var(--border-color);
    position: relative;
}

.headerAddContent {
    position: absolute;
    top: var(--mb-3);
    left: 0;
    background-color: var(--modal-color);
    display: flex;
    width: 100%;
    height: auto;
    font-size: var(--normal-font-size);
    font-weight: var(--font-normal);
    padding: 16px;
    cursor: default;
    border-bottom: 1px solid var(--border-color);
    z-index: 100;
}
.modal-header:active {
    cursor: grabbing;
}

.headerAddContent form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.headerAddContent form .headerFormRow {
    display: flex;
}
.headerFormRow input {
    display: flex;
    width: 100%;
    margin: 0 var(--mb-0-5) var(--mb-0-5) 0;
    outline: none;
    background: initial;
    color: var(--text-second-color);
    border: none;
    border-bottom: 1px solid var(--border-color);
    padding: var(--mb-0-25) var(--mb-0-5);
    font-size: var(--normal-font-size);
    font-weight: var(--font-extra-light);
}
.headerFormRow input:last-child {
    margin-right: 0;
}
.headerFormRow input:focus,
.headerFormRow select {
    border-color: var(--white-color);
}
/*.headerFormRow input:required {*/
/*    border-color: var(--red-color);*/
/*}*/
.headerFormRow select {
    display: flex;
    width: 100%;
    margin: 0 var(--mb-0-5) var(--mb-0-5) 0;
    padding: var(--mb-0-25) var(--mb-1);
    outline: none;
    background: initial;
    color: var(--text-input-color);
    border: none;
    border-bottom: 1px solid var(--border-color);
    font-size: var(--normal-font-size);
    font-weight: var(--font-extra-light);
}

.headerFormButton {
    outline: none;
    border: none;
    color: var(--white-color);
    background: initial;
    transform: scale(120%);
    margin-left: var(--mb-0-5);
    cursor: pointer;
}

.sendQiwiButton{
    background: initial;
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--purple-color);
    font-size: var(--font-medium);
}
.sendQiwiButton:hover{
    color: var(--purple-hover-color);
}

/* TODO: Лист аккаунтов */

.account_card_list {
    display: flex;
    flex-direction: row;
}

.account_card_list .card_list {
    display: flex;
    flex-direction: row;
}
.card_list .card {
    cursor: grab;
}
.card_list .card:after {
    content: '';
}

    /* TODO: Панель управления */

main {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.main_wrapper {
    display: flex;
    justify-content: center;
}
.main_wrapper .main_card {
    max-width: 410px;
}

.dashboard {
    display: flex;
    flex-direction: column;

}
.dashboard .dash_row {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--mb-1-5);
    justify-content: center;
}

@keyframes show {
    from { scale: 0 }
    to { scale: 1; }
}

.card {
    display: flex;
    background-color: var(--container-color);
    border: 1px solid var(--border-color);
    border-radius: 20px;
    margin-right: var(--mb-1-5);
    animation: 1s show ease;
}
.card:last-child {
    margin-right: 0;
}
.card .card_row {
    padding: var(--mb-0-75);
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--border-color);
}
.card .card_row:last-child {
    border-bottom: none;
}

.card_placeholder {
    background-color: var(--container-second-color);
    border: 1px solid var(--border-color);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.card_placeholder:first-child {
    margin-right: var(--mb-0-75);
}
.card_placeholder .stats {
    display: flex;
    flex-direction: column;
    padding: var(--mb-0-5) var(--mb-0-75);
}
.stats .stats_title {
    font-size: var(--small-font-size);
    font-weight: var(--font-extra-light);
    color: var(--text-input-color);
    padding: var(--mb-0-5) 0;
}
.stats .stats_info {
    font-size: var(--h3-font-size);
    font-weight: var(--font-normal);
    color: var(--text-color);
}
.card_placeholder .stats_img {
    display: flex;
    padding: 0 var(--mb-0-75);
    font-size: var(--h1-font-size);
    background: var(--purple-gradient);
    background: var(--webkit-purple-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: .9;
}
.card_placeholder .stats_img.error {
    background: var(--red-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card_row button:hover {
    opacity: .9;
}

.btn_transactions,
.btn_accounts,
.btn_games {
    width: 81px;
    padding: var(--mb-1);
    border: none;
    outline: none;
    background: initial;
    border-radius: 18px;
    color: var(--text-second-color);
    font-size: var(--h1-font-size);
    margin-right: var(--mb-0-75);
    cursor: pointer;
}

.btn_transactions:last-child,
.btn_accounts:last-child,
.btn_games:last-child {
    margin-right: 0;
}

.btn_transactions {
    background: var(--red-gradient);
}
.btn_games {
    background: var(--blue-gradient);
}
.btn_accounts {
    background: var(--purple-gradient);
}

.btn_transactions i,
.btn_accounts i,
.btn_games i{
    background: var(--white-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.trans_info {
    max-width: 47.282rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.trans_info .card_row {
    border: none;
}
.trans_info .card_row:first-child {
    border-bottom: 1px solid var(--border-color);
}
.trans_info .card_row:last-child {
    border-bottom: none;
}

.trans_info .trans_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: var(--mb-0-25) 0;
    align-items: center;
}

.trans_header .trans_title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    user-select: none;
}
.trans_header .trans_title.close {
    display: none;
}
.trans_header .trans_search {
    line-height: 1px;
}
.trans_header .trans_search button {
    border: none;
    outline: none;
    background: initial;
    color: var(--text-color);
    font-size: var(--h3-font-size);
    cursor: pointer;
}

.trans_input {
    position: relative;
    width: 95%;
}
.trans_input input {
    display: flex;
    outline: none;
    border: none;
    border-bottom: 1px solid var(--border-color);
    background: initial;
    color: var(--text-input-color);
    font-weight: var(--font-extra-light);
    font-size: var(--normal-font-size);
    padding: var(--mb-0-25) var(--mb-0-75);
    width: 100%;
}
.trans_input input:focus {
    border-bottom: 1px solid var(--purple-color);
}
.trans_input button {
    position: absolute;
    top: 4px;
    right: 5px;
    border: none;
    outline: none;
    background: initial;
    color: var(--text-color);
    font-size: var(--h3-font-size);
    cursor: pointer;
}

.trans_main {
    display: flex;
    width: 100%;
}
.trans_main_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border: 1px solid var(--border-color);
    padding: var(--mb-0-5) var(--mb-0-75);
    border-radius: 20px;
}
.trans_main_info .trans_info_left {
    display: flex;
    flex-direction: column;
    margin-left: var(--mb-0-25);
}
.trans_info_left .trans_info_title {
    padding: var(--mb-0-25) 0;
    font-size: var(--h3-font-size);
}
.trans_info_left .trans_info_link {
    font-size: var(--small-font-size);
    font-weight: var(--font-extra-light);
    color: var(--text-input-color);
    margin-bottom: var(--mb-0-25);
}
.trans_info_left .trans_info_link span:first-child:focus{
    border: none;
    outline: none;
    border-bottom: 1px solid var(--white-color);
}
.trans_info_left .trans_info_link span:last-child button{
    outline: none;
    border: none;
    background: initial;
    color: var(--white-color);
    cursor: pointer;
    margin-left: var(--mb-0-5);
    transform: scale(130%);
}

.trans_main_info .trans_info_right {
    display: flex;
    align-items: center;
}
.trans_main_info .trans_info_right .trans_info_img {
    display: flex;
    font-size: var(--h1-font-size);
    background: var(--blue-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.trans_info_img .bx-joystick {
    background: var(--purple-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.trans_info_img .bx-time-five {
    background: var(--red-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.card_row_button {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.card_row_button button,
.card_row_button select {
    outline: none;
    border: none;
    background: initial;
    color: var(--text-color);
    font-size: var(--normal-font-size);
    padding: var(--mb-0-75);
    border-top: 1px solid var(--border-color);
    cursor: pointer;
}
.card_row_button button:hover,
.card_row_button select:hover{
    background: var(--container-second-color);
}

.tabs_row {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
}
.tabs_row button {
    display: flex;
    width: 100%;
    margin: 0;
    border: none;
    outline: none;
    background: initial;
    color: var(--text-color);
    /*justify-content: center;*/
    padding: var(--mb-0-5) var(--mb-1);
    cursor: pointer;
    border-bottom: 1px solid var(--border-color);
    font-size: var(--normal-font-size);
    font-weight: var(--font-extra-light);
}
.tabs_row button:first-child {
    /*border-right: 1px solid var(--border-color);*/
}
.tabs_row button:last-child {
    border-left: 1px solid var(--border-color);
}
.tabs_row button.active {
    background-color: var(--container-second-color);
    border-bottom: none;
}

.tabs_row button:last-child {
    display: flex;
    justify-content: space-between;
}

/* TODO: Логи и прокси */


.logs_card {
    width: 100%;
    max-width: 750px;
}

.logs_row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.logs_row button {
    display: flex;
    padding: var(--mb-0-25) var(--mb-0-5);
    width: 100%;
    justify-content: center;
    font-weight: var(--font-light);
    font-size: var(--normal-font-size);
    border-radius: 20px;
    margin-right: var(--mb-0-5);
    cursor: pointer;
    background: var(--border-color);
    color: var(--text-color);
    border: 1px solid var(--border-color);
}
.logs_row button:last-child {
    margin-right: 0;
}

.btnProxy {
}
.btnLogs {
}
.btnLogsPrice {
}
.btnLogsAdmin {
}

    /* TODO: Таблицы */

table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 60px;
}
table thead{
    background-color: var(--container-second-color);
}

table tr th,
table tr td{
    text-align: left;
    padding: var(--mb-0-5) var(--mb-0-25);
    white-space: nowrap;
}
table tr th:first-child,
table tr td:first-child{
    padding-left: var(--mb-1);
}

table tr {
    border-bottom: 1px solid var(--border-color);
}

table tr:hover {
    background-color: var(--container-second-color);
}
table tr:last-child {
    margin-bottom: var(--mb-3);
}

td.hidden {
    position: relative;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: white-space 0.3s;
}
td.hidden:hover {
    overflow: initial;
}
.hover_text {
    display: none;
}
td.hidden:hover > .hover_text {
    position: absolute;
    background: var(--border-color);
    display: block;
    top: calc(var(--mb-0-25));
    left: -5px;
    padding: var(--mb-0-25) calc(var(--mb-0-25) + 5px);
    border-radius: 6px;
    z-index: 1;
}
td.hidden:hover > .visibility_text {
    visibility: hidden;
}
.copy_text {
    cursor: pointer;
}

.table_delete {
    outline: none;
    border: none;
    background: initial;
    color: var(--red-color);
    cursor: pointer;
    transform: scale(120%);
}
.table_delete:hover {
    color: var(--red-hover-color);
}


/* TODO: Загрузить еще... */

.showAll_button {
    display: flex;
    margin-bottom: 80px;
    margin-top: -40px;
    justify-content: center;
}

.showAll_button button {
    display: flex;
    background: initial;
    border: none;
    outline: none;
    color: var(--purple-color);
    font-size: var(--normal-font-size);
    font-weight: var(--font-light);
    cursor: pointer;
}
.showAll_button button:hover {
    color: var(--purple-hover-color);
}


    /* TODO: Чекбоксы */

input[type="checkbox"] {
    appearance: none;
    background-color: initial;
    border: 1px solid var(--white-color);
    color: var(--text-color);
    margin: 0;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    cursor: pointer;
}

input[type="checkbox"]:hover{
    opacity: .8;
    background: var(--white-color) url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEuMTMyNCAwLjE4OTcwNkMxMS4zOTU4IDAuNDQ2OTUgMTEuNDAwOCAwLjg2OTAzMSAxMS4xNDM2IDEuMTMyNDVMNC42MzMxOCA3Ljc5OTEyQzQuNTAyIDcuOTMzNDUgNC4zMjAyNSA4LjAwNjIzIDQuMTMyNjEgNy45OTk1OEMzLjk0NDk2IDcuOTkyOTMgMy43Njg4MiA3LjkwNzQ2IDMuNjQ3NDggNy43NjQxN0wwLjgyNDU2MyA0LjQzMDg0QzAuNTg2NjE2IDQuMTQ5ODcgMC42MjE0OTQgMy43MjkyIDAuOTAyNDY1IDMuNDkxMjZDMS4xODM0NCAzLjI1MzMxIDEuNjA0MSAzLjI4ODE5IDEuODQyMDUgMy41NjkxNkw0LjE5MTMyIDYuMzQzMkwxMC4xODk3IDAuMjAwODg1QzEwLjQ0NjkgLTAuMDYyNTMzMiAxMC44NjkgLTAuMDY3NTM4MSAxMS4xMzI0IDAuMTg5NzA2WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==') 50% 50% /70% no-repeat;
}

input[type="checkbox"]:checked {
    background: var(--white-color) url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEuMTMyNCAwLjE4OTcwNkMxMS4zOTU4IDAuNDQ2OTUgMTEuNDAwOCAwLjg2OTAzMSAxMS4xNDM2IDEuMTMyNDVMNC42MzMxOCA3Ljc5OTEyQzQuNTAyIDcuOTMzNDUgNC4zMjAyNSA4LjAwNjIzIDQuMTMyNjEgNy45OTk1OEMzLjk0NDk2IDcuOTkyOTMgMy43Njg4MiA3LjkwNzQ2IDMuNjQ3NDggNy43NjQxN0wwLjgyNDU2MyA0LjQzMDg0QzAuNTg2NjE2IDQuMTQ5ODcgMC42MjE0OTQgMy43MjkyIDAuOTAyNDY1IDMuNDkxMjZDMS4xODM0NCAzLjI1MzMxIDEuNjA0MSAzLjI4ODE5IDEuODQyMDUgMy41NjkxNkw0LjE5MTMyIDYuMzQzMkwxMC4xODk3IDAuMjAwODg1QzEwLjQ0NjkgLTAuMDYyNTMzMiAxMC44NjkgLTAuMDY3NTM4MSAxMS4xMzI0IDAuMTg5NzA2WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==') 50% 50% /70% no-repeat;
}

/* TODO: Digi placeholder */

.digi_placeholder {
    background-color: var(--container-second-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 2px var(--mb-0-25);
    font-size: var(--small-font-size);
    font-weight: var(--font-extra-light);
    margin-right: var(--mb-0-5);
}

@keyframes sendServer {
    0% {
        border-color: var(--border-color);
    }
    50% {
        border-color: var(--white-color);
    }
    100% {
        border-color: var(--border-color);
    }
}

.digi_placeholder.animation {
    animation: sendServer 1s ease;
}
input.animation,
select.animation{
    animation: sendServer 1s ease;
}
.digi_placeholder.percent:after {
    content: '%';
    font-size: var(--small-font-size);
    font-weight: var(--font-extra-light);
}

.digi_id:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid var(--white-color);
}

td .table_add {
    outline: none;
    background: initial;
    border: none;
    color: var(--text-color);
    line-height: 1px;
    cursor: pointer;
    transform: scale(120%);
    /*font-size: var(--normal-font-size);*/
}
td .table_add.delete {
    color: var(--red-color);
    margin-left: var(--mb-0-25);
}
td .table_add:hover {
    /*transform: rotate(45deg);*/
    color: var(--purple-color);
}
td .table_add.delete:hover {
    color: var(--red-hover-color);
}

@keyframes editableCellFocus {
    0% {
        border-bottom: 1px solid var(--border-color);
    }
    100% {
        border-bottom: 1px solid var(--white-color);
    }
}

td .editable_cell:focus {
    border: none;
    border-bottom: 1px solid var(--white-color);
    animation: editableCellFocus 0.5s ease;
}

@keyframes sendServerBottom {
    0% {
        border-bottom: 1px solid var(--border-color);
    }
    50% {
        border-bottom: 1px solid var(--white-color);
    }
    100% {
        border-bottom: 1px solid var(--border-color);
    }
}

td .editable_cell.animation {
    animation: sendServerBottom 1s ease;
}


/* TODO: Inline input(span) */

td span:focus {
    outline: none;
    border: 1px solid var(--white-color);
}

/* TODO: Селектор */

td select {
    outline: none;
    border: 1px solid var(--border-color);
    background: var(--container-second-color);
    color: var(--text-color);
    border-radius: 6px;
    padding: 2px var(--mb-0-5);
    font-weight: var(--font-extra-light);
}


.btnDataAdd {
    outline: none;
    border: 1px solid var(--white-color);
    background: initial;
    color: var(--white-color);
    /*padding: 5px;*/
    border-radius: var(--mb-0-25);
    margin-left: var(--mb-0-5);
    cursor: pointer;
    transition: color 0.5s ease, background-color 0.5s ease;
    font-size: var(--smaller-font-size);
}
.btnDataAdd:hover {
    background-color: var(--white-color);
    color: var(--black-color);
}
.btnDataAdd.btnPrices {
    padding: 0 var(--mb-0-25) 0 var(--mb-0-25);
}

.btnDataAddWallet {
    outline: none;
    border: none;
    background: initial;
    color: var(--white-color);
    margin-left: var(--mb-0-5);
    cursor: pointer;
    transition: color 0.5s ease, background-color 0.5s ease;
    font-size: 16px;
}
.btnDataAddWallet:hover {

}
.btnDataAddWallet.btnPrices {
    padding: 0 var(--mb-0-25) 0 var(--mb-0-25);
}


.disabled {
    opacity: .6;
    cursor: default;
}

.disabled:hover {
    background: initial;
    color: var(--white-color);
}


    /* TODO: Карточка Steam */

.steam_card {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.steam_card .steam_img {
    padding: var(--mb-0-75);
}
.steam_card .steam_img img {
    max-width: 80px;
    height: auto;
    border-radius: 50%;
}
.steam_card .steam_info {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.steam_card .steam_info .steam_name {
    font-size: var(--h2-font-size);
}
.steam_card .steam_info .steam_url {
    font-size: var(--normal-font-size);
    font-weight: var(--font-light);
    color: var(--text-input-color);
}
.steam_url span {
    outline: none;

    border-bottom: 1px solid var(--border-color);
}
.steam_url span:focus {
    border-bottom: 1px solid var(--purple-color);
}
.steam_url {
    display: flex;
    align-items: center;
}
.steam_url button {
    border: none;
    outline: none;
    font-size: var(--h3-font-size);
    background: initial;
    color: var(--text-color);
    line-height: 1px;
    margin-left: var(--mb-0-5);
    cursor: pointer;
}

.app_card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid var(--border-color);
    background-color: var(--container-second-color);
    border-radius: 20px;
    padding: var(--mb-0-75);
    margin: 0 var(--mb-0-5);
}
.app_info {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: var(--mb-0-5);
}
.app_info .app_title {
    display: flex;
    font-size: var(--h3-font-size);
    padding-bottom: var(--mb-0-25);
}
.app_info .app_url {
    display: flex;
    font-size: var(--normal-font-size);
    font-weight: var(--font-extra-light);
    color: var(--text-input-color);
}
.app_card .app_right {
    display: flex;
    align-items: center;
    font-size: var(--h1-font-size);
    background: var(--blue-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.trans_card_info {
    display: none;
    border-top: 1px solid var(--border-color);
}
.trans_card_info.active {
    display: block;
}

.trans_card_info .card_row {
    border: none;
}
.trans_status_card {
    display: flex;
    flex-direction: column;
    border-left: 2px solid var(--pink-color);
    margin: var(--mb-0-75);
    padding: 0 var(--mb-0-75);
}
.trans_status_card .trans_time {
    font-size: var(--h3-font-size);
}
.trans_status_card .status_info {
    font-size: var(--normal-font-size);
    font-weight: var(--font-light);
    color: var(--text-input-color);
}

.card_row.trans_buttons {
    padding: 0;
    border-top: 1px solid var(--border-color);

}

.card .card_row.trans_buttons {
    position: relative;
    display: flex;
    flex-direction: column;
}
.trans_buttons .trans_select,
.trans_buttons .trans_button{
    display: flex;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    padding: var(--mb-1);
    border-top: 1px solid var(--border-color);
    font-size: var(--normal-font-size);
    font-weight: var(--font-light);
}
.trans_buttons .trans_select:hover,
.trans_buttons .trans_button:hover {
    background-color: var(--container-second-color);
}

.trans_buttons .trans_select {
    border: none;
    outline: none;
    background-color: var(--container-color);
    color: var(--text-color);
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.select_arrow {
    position: absolute;
    cursor: pointer;
    pointer-events: none;
    top: 10px;
    right: 10px;
    font-size: var(--h2-font-size);
}

option {
    color: var(--black-color);
}


/* TODO: Main */

.main_gift {
    display: flex;
    flex-direction: column;
    /*max-width: 750px;*/
    width: 100%;
}

.main_card {

}

.main_img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: var(--bigger-font-size);
    padding: var(--mb-1);
}
.main_img i {
    background: var(--purple-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main_text {
    font-weight: var(--font-medium);
    font-size: var(--h3-font-size);
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: var(--mb-0-5);
}

.main_text span a {
    color: var(--text-color);
    text-transform: uppercase;
}

/* TODO: Footer */

footer {
    text-align: right;
}

footer .footer {
    margin: var(--mb-2) var(--mb-0-5) var(--mb-0-5) 0;
}

/* TODO: Gift */

.main_row {
    margin-bottom: var(--mb-2);
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.main_row:last-child {
    margin: 0;
}

.game_card {
    max-width: 750px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 227px;
}

.card_game_title {
    display: flex;
    justify-content: right;
    padding: var(--mb-0-5) var(--mb-0-75) 0 0;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
}

.card_game_title span a {
    color: var(--text-color);
}
.card_game_title span a:hover {
    /*color: var(--purple-hover-color);*/
    /*border-bottom: 1px solid var(--text-color);*/
}

.game_image {
    height: 190px;
    overflow: hidden;
    clip-path: path('M0 53.948C0 53.948 79.8843 0 178.092 0C276.301 0 298.625 22.0854 381.551 53.948C464.478 85.8105 511.621 108.595 595.064 108.595C678.507 108.595 750 53.948 750 53.948V190H0V53.948Z');
}
.game_image img {
    border-radius: 0 0 20px 20px;
}


/* TODO: Profile */
.profile_card {
    max-width: 360px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 441px;
}

.profile_image{
    display: flex;
    justify-content: center;
    margin-top: var(--mb-3);
}

.profile_image img {
    border-radius: 50%;
    height: auto;
    width: 133px;
}
.profile_title {
    margin-top: var(--mb-0-5);
    text-align: center;
    font-size: var(--h2-font-size);
}

.profile_link {
    text-align: center;
    margin-top: var(--mb-0-5);
    font-size: var(--normal-font-size);
    font-weight: var(--font-extra-light);
    color: var(--text-input-color);
    padding: 0 var(--mb-0-5);
    word-wrap: break-word;
    word-break: break-all;
}

.profile_button{
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: 400;
    margin: var(--mb-2) 0;
}
.profile_button a{
    color: var(--purple-color);
}
.profile_button a:hover{
    color: var(--purple-hover-color);
}

/* TODO: Status */

.status_card {
    max-width: 360px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 441px;
}

.succ_image{
    display: flex;
    justify-content: center;
    margin-top: var(--mb-3);
    font-size: var(--big-font-size);
}
.succ_image span {
    background: var(--blue-gradient);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.succ_image span.errorIcon {
    background: var(--red-gradient);
}


.succ_image i {
    background: var(--white-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.succ_title {
    margin-top: var(--mb-0-5);
    text-align: center;
    font-size: var(--h2-font-size);
}

.succ_description {
    text-align: center;
    margin-top: var(--mb-0-5);
    font-size: var(--normal-font-size);
    font-weight: var(--font-extra-light);
    color: var(--text-input-color);
    padding: 0 var(--mb-0-25);
    word-wrap: normal;
    word-break: normal;
}
.succ_button{
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: 400;
    margin: var(--mb-2) 0;
}

.succ_button a {
    color: var(--purple-color);
}
.succ_button a:hover {
    color: var(--purple-hover-color);
}

/* TODO: Доставка прогресс */

.progress_status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.progress_row {
    padding: var(--mb-2) var(--mb-1);
    border-bottom: 1px solid var(--border-color);
    display: flex;
    flex-direction: row;
}

.progress_row:last-child {
    border-bottom: none;
}

.progress_image .progress_circle {
    display: flex;
    width: 45px;
    height: 45px;
    border: 1px solid var(--border-color);
    border-radius: 50%;
    background-color: initial;
    justify-content: center;
}

.progress_image .progress_circle img {
    display: none;
}
.progress_image .progress_circle.completed {
    background: var(--blue-gradient);
}
.progress_image .progress_circle.completed > .check {
    display: block;
    width: 80%;
}

.progress_image .progress_circle.processing {
    background: var(--red-gradient);
}
.progress_image .progress_circle.processing > .processing {
    display: block;
    filter: invert();
    width: 40%;
}

.progress_title {
    align-items: center;
    display: flex;
    margin-left: var(--mb-1);
    font-size: var(--normal-font-size);
    font-weight: var(--font-light);
}

/* TODO: Авторизация */
.main_auth {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    height: calc(100vh - 220px - var(--header-height));
    align-items: center;
    justify-content: center;
}

.main_auth_row {
    display: flex;
    margin-bottom: var(--mb-1);
    width: 100%;
}
.auth_card {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.auth_card .auth_img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--border-color);
}
.auth_card .auth_img_place {
    margin: 0 var(--mb-3);
}

.auth_img_place i {
    font-size: var(--bigger-font-size);
    background: var(--purple-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.auth_card .auth_title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-bold);
    text-align: center;
    padding: var(--mb-1);
}

.auth_card form {
    width: 100%;
}

.form_row {
    padding: var(--mb-0-5) var(--mb-1);
    width: 100%;
}

.form_row input {
    width: 100%;
    border: 1px solid var(--border-color);
    outline: none;
    padding: var(--mb-0-5) var(--mb-1);
    border-radius: 20px;
    background: var(--input-color);
    color: var(--text-input-color);
    font-weight: var(--font-extra-light);
    font-size: var(--small-font-size);
}
.form_row input:focus {
    border: 1px solid var(--purple-color);
}
.form_row button {
    outline: none;
    width: 100%;
    border: none;
    border-radius: 20px;
    padding: var(--mb-0-25);
    background: var(--purple-gradient);
    font-size: var(--normal-font-size);
    font-weight: var(--font-light);
    color: var(--text-color);
}
.form_row:last-child {
    margin-bottom: var(--mb-1);
}

    /* TODO: Steam Status */

.steam_status {
    max-width: 750px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.steam_status .steam_status_img {
    display: flex;
    padding: var(--mb-1);
    font-size: var(--h2-font-size);
    color: var(--red-color);
    align-items: center;
}

.steam_status .steam_status_title {
    padding: var(--mb-0-25) var(--mb-0-25) var(--mb-0-25) 0;
}

/* TODO: Прелоадер */

@keyframes shine {
    to {
        background-position-x: -300%;
    }
}

.loader {
    display: flex;
    /*max-width: 750px;*/
    max-width: 100%;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.skeleton_loader {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: 1px solid var(--border-color);
    background: linear-gradient(110deg, var(--container-color) 15%, var(--border-color) 50%, var(--container-color) 75%);
    background-size: 300% 100%;
    animation: 3s shine linear infinite;
}
.modal-content .loader .skeleton_loader {
    border-radius: 0;
    border: none;
}
.game_card > .loader{
    max-width: 750px;
    width: 100%;
}

.carousel_img > .loader {
    max-width: 310px;
    width: 100%;
    height: 200px;
}




/* TODO: Карусель и стрелки */


.main_gift {
    position: relative;
}

.main_gift .down {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;
}
.main_gift .down .down_left,
.main_gift .down .down_right{
    display: flex;
    width: 10vw;
    height: 100%;
    /*background: rgba(255,255,255,0.5);*/
    justify-content: center;
    pointer-events: painted;
    cursor: pointer;
}
.main_gift .down .down_right {
    margin-left: 800px;
}
.main_gift .down .down_left .down_arrow,
.main_gift .down .down_right .down_arrow {
    display: flex;
    align-items: center;
    font-size: var(--bigger-font-size);
    margin-top: 400px;
}

.main_gift .down .down_left .down_arrow i,
.main_gift .down .down_right .down_arrow i {
    background: linear-gradient(0deg, rgba(255,255,255,0.4) 15%, rgb(140, 99, 223) 45%, rgba(255,255,255,0.4) 75%);
    background-size: 200% 200%;
    animation: 1s arrow_shine ease-out infinite;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
}

@keyframes arrow_shine {
    0% {
        background-position-y: 0;
    }
    /*50% {*/
    /*    background-position-y: -100%;*/
    /*}*/
    /*80% {*/
    /*    background-position-y: -180%;*/
    /*}*/
    100% {
        background-position-y: -200%;
    }
}


.row_carousel {
    position: relative;
    margin-top: var(--mb-2);
}

.wrapper{
    display: flex;
    max-width: 100vw;
    width: 100%;
    background: var(--container-color);
    border-bottom: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    position: relative;
    flex-direction: column;
}

.wrapper .wrapper_title {
    display: flex;
    justify-content: center;
    font-size: var(--h2-font-size);
    padding: var(--mb-0-75) 0 var(--mb-1) 0;
}


.wrapper .left {
    position: absolute;
    height: 110%;
    width: 250px;
    display: flex;
    top: -10px;
    left: -5px;
    /*pointer-events: none;*/
    cursor: pointer;
    background:  linear-gradient(to left, rgba(0, 0, 0, 0), rgba(14, 14, 14, .7), var(--body-color));
    z-index: 1;
    justify-content: left;
    padding-left: var(--mb-1);
}
.wrapper .right {
    position: absolute;
    height: 110%;
    width: 250px;
    display: flex;
    top: -10px;
    right: -5px;
    /*pointer-events: none;*/
    cursor: pointer;
    background:  linear-gradient(to right, rgba(0, 0, 0, 0), rgba(14, 14, 14, .7), var(--body-color));
    z-index: 1;
    justify-content: right;
    padding-right: var(--mb-1);
}
.wrapper .right:hover {
    background:  linear-gradient(to right, rgba(0, 0, 0, 0), var(--body-color));
}
.wrapper .left:hover {
    background:  linear-gradient(to left, rgba(0, 0, 0, 0), var(--body-color));
}
.wrapper .left span,
.wrapper .right span {
    display: flex;
    align-items: center;
    font-size: var(--h1-font-size);
    opacity: .3;
}
/*.wrapper .left:hover > .wrapper .left span,*/
/*.wrapper .right:hover > .wrapper .right span {*/
/*    opacity: .5;*/
/*}*/

.wrapper .carousel{
    cursor: default;
    overflow: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
    flex-direction: row;
    display: flex;
    padding: 0 0 var(--mb-1) 0;
}
.carousel_card {
    display: flex;
    width: 310px;
    /*background: var(--text-second-color);*/
    margin-left: var(--mb-1);
    flex-shrink: 0;
    flex-direction: column;
    transition: transform 0.3s ease;
}

.carousel_card:first-child {
    margin-left: var(--mb-3);
}
.carousel_card:last-child {
    margin-right: var(--mb-3);
}

.carousel_card:hover {
    transform: scale(98%);
}

.carousel.dragging{
    cursor: grab;
    scroll-behavior: auto;
}
.carousel.dragging img{
    pointer-events: none;
}
.carousel_img {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    font-size: 0;
}

.carousel_img .img_blur {
    position: absolute;
    width: 100%;
    top: 70%;
    filter: blur(20px);
    height: 70%;
    object-fit: fill;
}

.carousel_img img{
    width: 310px;
    object-fit: cover;
    user-select: none;
    height: 185px;
    border-radius: 20px;
}

.carousel_desc {
    margin-top: var(--mb-0-5);
}

.carousel_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: var(--mb-0-5);
}

.carousel_desc .carousel_title {
    font-weight: var(--font-semi-bold);
    font-size: 20px;
    text-align: justify;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.carousel_price {
    font-size: var(--normal-font-size);
    font-weight: var(--font-light);
    color: var(--text-second-color);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.carousel_price .circle {
    content: "";
    display: flex;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--text-second-color);
    margin: 0 var(--mb-0-5);
}

.carousel_price .price {

}

.carousel_buy {

}

.carousel_buy .button_buy {
    border: none;
    outline: none;
    border-radius: 38.5px;
    background: var(--purple-gradient);
    color: var(--text-color);
    padding: var(--mb-0-25) var(--mb-1-5);
    font-weight: var(--font-extra-light);
    font-size: var(--normal-font-size);
    cursor: pointer;
}

/*.carousel img:first-child{*/
/*    margin-left: 0px;*/
/*}*/
/*@media screen and (max-width: 900px) {*/
/*    .carousel img{*/
/*        width: calc(100% / 2);*/
/*    }*/
/*}*/
/*@media screen and (max-width: 550px) {*/
/*    .carousel img{*/
/*        width: 100%;*/
/*    }*/
/*}*/



/* TODO: QIWI */

.qiwi_card {
    justify-content: start;
    overflow: hidden;
}
.qiwi_card .profile_image {
    margin-top: 0;
}
.qiwi_card .profile_image img {
    border-radius: 0;
    height: auto;
    width: auto;
}

.qiwi_card .profile_title {
    margin-top: var(--mb-3);
}

.main_container.qiwi {
    margin-bottom: var(--mb-3);
}



/* TODO: Анимации */

@keyframes show {
    from { opacity: 0 }
    to { opacity: 1 }
}

.card {
    animation: 1s show ease-in;
}

.game_card {
    animation: 1s show ease-in;
}

.profile_card {
    animation: 1s show ease-in;
}
.status_card {
    animation: 1s show ease-in;
}
.wrapper {
    animation: 1s show ease-in;
}
input {
    transition: border-color 0.3s ease;
}
a {
    transition: color 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.searchResult {
    animation: fadeIn 0.5s ease; /* You can adjust the duration and easing here */
}

@keyframes sendServerTrans {
    0% {
        color: var(--white-color);
    }
    50% {
        color: var(--purple-color);
    }
    100% {
        color: var(--white-color);
    }
}

.card_row_button button.animation {
    animation: sendServerTrans 1s ease;
}
@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg) scale(120%);
    }
    100% {
        transform: rotate(360deg) scale(120%);
    }
}
.bx-refresh.animationRoll {
    animation: rotateAnimation 1s ease;
}

/* TODO: Адаптация */

@media screen and (max-width: 968px) {
    .main_row {
        flex-direction: column;
    }
    main {
        /*margin: 0 auto;*/
    }

    .main_container {
        display: flex;
        justify-content: center;
    }
    .main_gift {
        max-width: 750px;
    }
    .main_gift .down {
        display: none;
    }
    .profile_card,
    .status_card{
        max-width: 750px;
        width: 100%;
        height: 320px;
        margin-bottom: var(--mb-2);
    }
    .card {
        border-radius: 14px;
    }
    .game_image {
        margin-top: var(--mb-2);
    }
    .progress_row {
        padding: var(--mb-1) var(--mb-1);
    }
    .wrapper .wrapper_title {
        font-size: var(--h3-font-size);
    }
    .carousel_card {
        max-width: 250px;
    }
    .carousel_img img {
        width: 250px;
        height: auto;
    }
    .carousel_info {
        margin-top: 4px;
    }
    .carousel_desc .carousel_title {
        font-size: var(--normal-font-size);
    }
    .carousel_buy .button_buy {
        padding: 1px var(--mb-0-5);
    }
    .wrapper .right,
    .wrapper .left{
        width: 90px;
    }
}